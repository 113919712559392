<template>
<div>
    <div class="header">
        <div class="htitle">错题本</div>
        <div class="close">
            <router-link to="/efficiency">
                <el-image style="width: 3.2vh; height: 3vh" fit="cover" :src="require('@/assets/close.png')"></el-image>
            </router-link>
        </div>
    </div>
    <div class="body">
        <div>
            <br/>
            <div>In development!</div>

            <el-image fit="fit" style="width: 90vw; height: 50vh" src="https://thesongof404.top:3000/blog/Saly-9.png"></el-image>
        </div>
    </div>
</div>
</template>

<script>
export default {};
</script>

<style scoped>
/* header部分 */
.header {
    position: relative;
    height: 10vh;
    background-color: rgb(250, 250, 251);
}

.htitle {
    line-height: 10vh;
    font-size: 1rem;
}

.close {
    position: absolute;
    top: 3.3vh;
    right: 5vw;
}

/* body部分 */
.body {
    margin: 0 5vw;
    /* background-color: rgb(132, 189, 236); */
}
</style>
